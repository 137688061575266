import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    formData = {
        mid: 'B10002',
        grossAmount: '12.00',
        netAmount: '12.00',
        rrn: '1234567987654321',
        surl: 'https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse',
        furl: 'https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse',
        orderNo: 'O12345',
        metaInfo: '',
        mobile: '8369032578',
        email: 'test@gmail.com',
        user: 'test@gmail.com',
        name: 'Mike',
        udf1: 'udf1',
        udf2: 'udf2',
        udf3: 'udf3',
        udf4: 'udf4',
        udf5: 'udf5',
        udf6: 'udf6',
        udf7: 'udf7',
        udf8: 'udf8',
        udf9: 'udf9',
        udf10: 'udf10',
        checksum: 'fcaea16f275d4d97eab6fc134e0b9d830d3e800233f5b3e7c1e2df8f7f558f9e3e17a93c1a265fe253348e8e523253d0ba62426c425cecc2fb2d8905424d831d',
      };
      constructor(private http: HttpClient) {}

       
    ngOnInit(): void {}
    submitForm(){
    //     this.http.post('https://pguat.zavepay.com/pg-wrapper-checkout/checkout/initiate/paymentRequest', this.formData).subscribe((response:any) => {debugger
    //   console.log('Payment Response:', response);
    //   window.location.href=response?.redirectURL

    // });
       document.getElementById('submitbtn')?.click()
    }
    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

    // Accordion
    accordionItems = [
        {
            title: 'Great Understanding',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Update Technology',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Experienced Team',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Best Quality Service',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        }
    ];
    selectedItem : any = null;
    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
    
    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}