<ng-container *ngIf="!hideLayout">
    <app-navbar></app-navbar>
  </ng-container>
  
  <router-outlet></router-outlet>
  
  <ng-container *ngIf="!hideLayout">
    <app-footer></app-footer>
  </ng-container>
  
  <ngx-scrolltop></ngx-scrolltop>