import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    hideLayout: boolean = false; // Flag to control header/footer visibility

    constructor(private router: Router) {}

    ngOnInit() {
        this.recallJsFuntions();
        this.setupLayoutVisibility();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    setupLayoutVisibility() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                // List of routes where header/footer should be hidden
                const noLayoutRoutes = ['/payment-gateway', '/transaction-receipt'];
    
                // Check if the current URL includes a route with a dynamic parameter (e.g. "/payment-gateway/:id")
                const hasDynamicRoute = noLayoutRoutes.some(route => {
                    // Replace ':id' with a regex pattern to match dynamic segments
                    const regex = new RegExp('^' + route.replace('/:id', '/\\w+') + '(/.*)?$');
                    return regex.test(event.urlAfterRedirects);
                });
    
                // Set hideLayout to true if the current route is in the noLayoutRoutes or matches the dynamic pattern
                this.hideLayout = hasDynamicRoute;
            });
    }
    
    
}
