import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-payment-gateway",
    templateUrl: "./payment-gateway.component.html",
    styleUrl: "./payment-gateway.component.scss",
})
export class PaymentGatewayComponent implements OnInit{
    ngOnInit(): void {
    
    }
  
    ngOnDestroy(){
        document.body.className="";
      }
}
