<!-- <div class="iframe-container" >
         <iframe src="/payments/assets/index1.html" style="width: 100%;height: 100vh;" ></iframe>
    </div> -->
<nav class="sticky-top bg-white shadow-sm"  >
    <div class="container">
        <div class="row ">
            <!-- Left Section: Logo -->
            <div class="col-4 col-md-2 text-start">
                <a href="#" class="logo">
                    <img id="imgSource" src="/payments/assets/images/logo-view.png" alt="Logo" style="height: 40px;">
                    <!-- <img id="imgSource" src="/assets/images/logo-view.png" alt="Logo" style="height: 40px; "> -->
                </a>
            </div> 
        </div>
    </div>
</nav>
<div class="container mt-5" >
    <div class="col-md-12 col-lg-8 col-xl-6 mb-4 " style="margin-left: auto;margin-right: auto;" >
    <div class="card bg-white shadow p-4">
    <div class="text-left p-1 ml-2" [style.display]="status === '200' ? 'block' : 'none'">
        <!-- <i class="far fa-credit-card fa-2x text-success"></i> -->
        <img src="/payments/assets/images/success.png" alt="Logo" >

        <h5 class="  mt-2" style="color: #1bb24b;">Transaction Successful!</h5>
        <p class="small">Thank you! <br />Your payment of  Rs.{{ transactionData?.netAmount }} has been received by <strong>ZavePay Technologies</strong>.
            <br /><strong class="text-muted">Transaction ID: {{ transactionData?.txnId }}</strong></p>
    </div>
    <div class="text-left p-1 ml-2" [style.display]="(status === '220'||status === '223'||status === '224'||status === '222'||status === '225') ? 'block' : 'none'">
        <!-- <i class="far fa-credit-card fa-ban fa-2x text-danger"></i> -->
        <img src="/payments/assets/images/failed.png" alt="Logo"  >

        <h5 class="text-danger mt-2">Transaction Failed!</h5>
        <p class="small">Hey there! <br />something went wrong <br />
           Please update or check your payment method to continue .
            <br /></p>
    </div>
    <div class="text-left p-1 ml-2" [style.display]="status === '210' ? 'block' : 'none'">
        <!-- <i class="fas fa-exclamation-triangle fa-2x text-warning"></i> -->
        <img src="/payments/assets/images/pending.png" alt="Logo" height="60px!important" >
        <h5 class="  mt-2" style="color: #c29712;">Transaction Pending!</h5>
        <p class="small">Hey there!<br /> Please <strong>do not make</strong> this payment again.
            <br /> Please wait for the transaction to complete/fail</p>
    </div>
    <div class="container mt-2">
        <h3>Transaction Details</h3>
        <!-- Make table scrollable on smaller screens -->
        <div class="table-responsive">
        <table class="table border">
                <!-- <thead  > -->
                    <!-- <tr>
                        <th>Mid</th>
                        <th>{{ transactionData?.mid }}</th>
                    </tr> -->
                    <!-- <tr>
                        <th>Gross Amount</th>
                        <th>{{ transactionData?.grossAmount }}</th>
                    </tr> -->
                <!-- </thead> -->
                <tbody> 
                   
                    <tr>
                        <td>Net Amount</td>
                        <td>{{ transactionData?.netAmount }}</td>
                    </tr>
                    <!-- <tr>
                        <td>RRN</td>
                        <td>{{ transactionData?.rrn }}</td>
                    </tr> -->
                    <tr>
                        <td>Order No</td>
                        <td>{{ transactionData?.orderNo }}</td>
                    </tr>
                    <tr>
                        <td>Mobile</td>
                        <td>{{ transactionData?.mobile }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ transactionData?.email }}</td>
                    </tr>
                    <tr>
                        <td>User</td>
                        <td>{{ transactionData?.user }}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{{ transactionData?.name }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Txn ID</td>
                        <td>{{ transactionData?.txnId }}</td>
                    </tr> -->
                    <tr>
                        <td>Txn Status</td>
                        <td>{{ transactionData?.txnStatus }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Status Code</td>
                        <td>{{ transactionData?.statusCode }}</td>
                    </tr> -->
                    <!-- <tr>
                        <td>Error Code</td>
                        <td>{{ transactionData?.errorCode }}</td>
                    </tr> -->
                    <!-- <tr>
                        <td>Bank Ref No</td>
                        <td>{{ transactionData?.bankRefNO }}</td>
                    </tr> -->
                    <tr>
                        <td>Payment Mode</td>
                        <td>{{ transactionData?.paymentMode }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Checksum</td>
                        <td>{{ transactionData?.checksum }}</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>

    <!-- Home Button Section -->
    <div class="text-center mt-4">
        <p>Click to Home to return</p>
        <button class="btn btn-outline-success" routerLink="/">Home</button>
    </div>
    </div>
    </div>
</div>