import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-seamless-payment',
  templateUrl: './seamless-payment.component.html',
  styleUrl: './seamless-payment.component.scss'
})
export class SeamlessPaymentComponent implements OnInit{
  transactionData: any;
  id: any;
  response: any;
  apiUrl: string;
  status: any;

  constructor(private Router:Router,private activatedRoute:ActivatedRoute,private http:HttpClient) {
    debugger
    this.id=activatedRoute.snapshot.params.id
   }

  ngOnInit(): void {
    // This would be replaced with your actual API call
    // For now, we are using a static response (as per your JSON) 
    this.apiUrl = 'https://pguat.zavepay.com/pg-wrapper-checkout/merchant/v1/'+this.id+'/txnMerchantResponse';
    this.http.post<any>(`${this.apiUrl}`, '').subscribe(
      (response) => {debugger
        console.log('Response:', response);
        this.response = response; // Store the response in a variable
    this.transactionData = this.response.transaction[0]
this.status =  this.transactionData.statusCode
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    //   this.transactionData = {
    //   "mid": "B10002",
    //   "grossAmount": "12.000",
    //   "netAmount": "12.000",
    //   "rrn": "1234567987654321",
    //   "surl": null,
    //   "furl": null,
    //   "orderNo": "O12345",
    //   "metaInfo": "",
    //   "mobile": "8369032578",
    //   "email": "test@gmail.com",
    //   "user": "test@gmail.com",
    //   "name": "Mike",
    //   "txnId": "2314cd6f7098",
    //   "txnStatus": "SUCCESS",
    //   "statusCode": "200",
    //   "errorCode": "E000",
    //   "errorDesc": null,
    //   "bankRefNO": "282828909924",
    //   "paRefNo": "S241130073WGXO",
    //   "paymentMode": "CC",
    //   "paymentCode": "1",
    //   "udf1": "udf1",
    //   "udf2": "udf2",
    //   "udf3": "udf3",
    //   "udf4": "udf4",
    //   "udf5": "udf5",
    //   "udf6": "udf6",
    //   "udf7": "udf7",
    //   "udf8": "udf8",
    //   "udf9": "udf9",
    //   "udf10": "udf10",
    //   "checksum": "a74144b9d67a4c4e5c934ee808a71b3e6fefc21b006249438ea139d855885a2dbfb9c73c10d20d10614f2ce02cf3edb0e4fca44bfdda31d456ca357c62228a44"
    // };
  }
}
